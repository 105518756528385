// CKOL theme.

@use "sass:math";

@import "webfonts";
@import "bootstrap_custom";

// WARNING: Some styles below are copied from Kerko. Kerko's stylesheet is not
// used, thus any change to Kerko's stylesheet may need to be applied here.

// General.

.list-no-bullets {
  list-style-type: none;
}
.no-decorate:hover,
.no-decorate:focus {
  text-decoration: none;
}
:hover > .parent-decorate,
:focus > .parent-decorate {
  text-decoration: underline;
}
.no-js .print-link {
  display: none;
}
.break-word {
  word-wrap: break-word; /* Older, non-standard name for overflow-wrap. */
  overflow-wrap: break-word;
}
.pre-line {
  white-space: pre-line;
}
.breadbox > .card-body > * {
  margin-top: 1rem;
}
.breadbox > .card-body > *:first-child {
  margin-top: 0;
}
.navbar-brand {
  white-space: normal;
}
.dropdown-width-300 {
  width: 300px;
  max-width: 90vw;
}
.dropdown-item {
  white-space: normal;
}
.btn-expand[aria-expanded="true"] {
  display: none;
}
.btn-expand[aria-expanded="true"] + .btn-collapse {
  display: inline;
}
.btn-expand[aria-expanded="false"] {
  display: inline;
}
.btn-expand[aria-expanded="false"] + .btn-collapse {
  display: none;
}
.btn-toggle[active] > .btn-toggle-icon-off {
  display: none;
}
.btn-toggle:not([active]) > .btn-toggle-icon-on {
  display: none;
}
.d-none-important {
  display: none !important;
}
.continued-hint {
  -webkit-mask-image: linear-gradient(to top, transparent, black 2.75rem);
  mask-image: linear-gradient(to top, transparent, black 2.75rem);
}

// Layout.

main {
  min-height: calc(100vh - 25rem);
  padding-top: 1rem;
  padding-bottom: 3rem;
}

// Main footer.

footer {
  font-family: "GillSansMTPro", Arial, Helvetica, "Helvetica Neue", sans-serif;
}
.footer-ack {
  background: #f2f2f2;
  a {
    color: $body-color;
    &:hover {
      color: $body-color;
    }
  }
}
.main-footer {
  color: rgba($white, 0.8);
  background: #912338;
  a {
    color: rgba($white, 0.8);
    &:hover {
      color: $white;
    }
  }
}

// Navbar.

.navbar {
  font-family: "GillSansMTPro", Arial, Helvetica, "Helvetica Neue", sans-serif;
  line-height: 1;
  .navbar-toggler {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.navbar-nav .active > .nav-link {
  background: rgb(110, 110, 110);
}
.navbar-nav {
  .nav-link {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    &:hover {
      color: rgb(75, 75, 75) !important;
      background: $white !important;
    }
    &.lang-toggle {
      background: $black;
      &:hover {
        color: $white !important;
        background: rgb(95, 95, 95) !important;
      }
    }
  }
}

// Badge.

.badge {
  font-family: Arial, Helvetica, "Helvetica Neue", sans-serif;
  line-height: 1.5;
}

// Facets.

.facets {
  .h6.font-weight-bold {
    font-family: $font-family-sans-serif;
    color: $black;
  }
}

// Search.

.search-item-resource-type {
  font-size: 0.875rem;
  color: $gray-600;
  border: 1px solid $gray-300;
  padding: 0.15rem 0.5rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

// Breadcrumb.

.breadcrumb {
  .breadcrumb-item {
    padding-right: 0.5rem;
    &:last-child {
      padding-right: 0;
    }
    &::before {
      padding-right: 0.5rem !important;
    }
    a {
      font-weight: bold;
      color: $dark;
    }
  }
}

// Cards.

.card-contributor {
  max-width: 35em;
}
.card-img-top {
  width: auto;
  height: 8rem;
  object-fit: scale-down;
  margin: $card-spacer-x; // Same spacing as .card-body.
}

// Print media styles.

@media print {
  .row {
    /*
      Fix for truncated pages in Firefox (issue with display: flex).
      Ref: https://bugzilla.mozilla.org/show_bug.cgi?id=939897
      Breaks column layouts, but we can live with that.
    */
    display: block !important;
  }
  .tab-content > .tab-pane {
    display: block;
    opacity: 1;
  }
  .text-md-right.text-print-left {
    text-align: left !important;
  }
  .breadbox a,
  .search-item a,
  .item-content a {
    text-decoration: none;
  }
}
